import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { StyledButton } from './Button';

const duration = 300;

const ButtonsSection = styled.section`
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  ${({ flex }) => flex && 'display: flex;'};
`;

const SharedStyleButton = styled.button`
  cursor: pointer;
  position: absolute;
  height: 100%;
  transition: transform ease;
  transition-duration: ${duration}ms;
  width: 50%;
`;

const StyledConfirmButton = styled(SharedStyleButton)`
  background-color: var(--secondary-color);
  border: none;
  border-radius: 8px 0 0 8px;
  color: var(--secondary-color-text);
  left: 0;
  right: 50%;
  transform: translate(${({ state }) => (['entering', 'entered'].includes(state) ? '-100%' : 0)}, 0);
`;
const CancelButton = styled(SharedStyleButton)`
  background-color: var(--danger-color);
  border: none;
  border-radius: 0 8px 8px 0;
  color: var(--white);
  left: 50%;
  right: 0;
  transform: translate(${({ state }) => (['entering', 'entered'].includes(state) ? '100%' : 0)}, 0);
`;

export default function ConfirmButton({
  fullWidth = false,
  onClick,
  titleCancel = 'Cancel',
  titleConfirm = 'Confirm',
  variant = 'primary',
  ...props
}) {
  const node = useRef();
  const [isActive, setIsActive] = useState(true);
  const onToggle = () => setIsActive(!isActive);

  const onConfirm = (event) => {
    onClick(event);
    onToggle();
  };

  return (
    <ButtonsSection aria-label={props.confirmType} flex={props.flex} fullWidth={fullWidth}>
      <StyledButton fullWidth={fullWidth} onClick={onToggle} variant={variant} type="button" {...props} />
      <Transition in={isActive} nodeRef={node} timeout={duration}>
        {(state) => (
          <>
            <StyledConfirmButton
              aria-label={titleConfirm}
              disabled={props.disabled}
              onClick={onConfirm}
              state={state}
              title={titleConfirm}
              type="button"
            >
              <FontAwesomeIcon icon={faCheckCircle} />
            </StyledConfirmButton>
            <CancelButton
              aria-label={titleCancel}
              disabled={props.disabled}
              onClick={onToggle}
              state={state}
              title={titleCancel}
              type="button"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </CancelButton>
          </>
        )}
      </Transition>
    </ButtonsSection>
  );
}

ConfirmButton.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  titleCancel: PropTypes.string,
  titleConfirm: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
};

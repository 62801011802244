/**
 * @generated SignedSource<<b77c6165124c34b9965ac229c4c2f60f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "category"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "CompetitionLeaderboard_tournament"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionConfiguration",
            "kind": "LinkedField",
            "name": "competitionConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CompetitionGroup",
                "kind": "LinkedField",
                "name": "competitionGroups",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "databaseId",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetitionPlayer",
                    "kind": "LinkedField",
                    "name": "competitionPlayers",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "holesLost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "holesWon",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "losses",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lossesAfterPenaltyPutts",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "points",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "wins",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "winsAfterPenaltyPutts",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Player",
                        "kind": "LinkedField",
                        "name": "player",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "splitCategories",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "round",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c6b330fc2c615a642537c1b6a8e569ae",
    "id": null,
    "metadata": {},
    "name": "CompetitionLeaderboardQuery",
    "operationKind": "query",
    "text": "query CompetitionLeaderboardQuery(\n  $category: String\n  $id: ID!\n) {\n  tournament(id: $id) {\n    ...CompetitionLeaderboard_tournament_15BdcN\n    id\n  }\n}\n\nfragment CompetitionLeaderboardGroup_competitionGroup on CompetitionGroup {\n  category\n  databaseId\n  id\n  name\n  competitionPlayers {\n    holesLost\n    holesWon\n    id\n    losses\n    lossesAfterPenaltyPutts\n    points\n    position\n    wins\n    winsAfterPenaltyPutts\n    player {\n      name\n      id\n    }\n  }\n}\n\nfragment CompetitionLeaderboardGroup_tournament on Tournament {\n  competitionConfiguration {\n    splitCategories\n    id\n  }\n  tournamentRounds {\n    round\n    id\n  }\n}\n\nfragment CompetitionLeaderboardGroups_tournament_15BdcN on Tournament {\n  competitionConfiguration {\n    competitionGroups(category: $category) {\n      id\n      ...CompetitionLeaderboardGroup_competitionGroup\n    }\n    id\n  }\n  ...CompetitionLeaderboardGroup_tournament\n}\n\nfragment CompetitionLeaderboard_tournament_15BdcN on Tournament {\n  ...CompetitionLeaderboardGroups_tournament_15BdcN\n  id\n}\n"
  }
};
})();

node.hash = "3bca14abe3d5cd8ffbebf7e9c1b95b7b";

module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ValidationMessage from './ValidationMessage';

const CheckboxStyle = styled.input`
  appearance: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
  float: left;
  height: 1em;
  margin-top: 0.25em;
  margin-left: -1.5em;
  transition: all 0.15s ease-in-out;
  transition-property: background-color, background-position, border-color, box-shadow;
  width: 1em;

  &:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

    ${(props) => props.hasErrors && 'background-color: var(--danger-color); border-color: var(--danger-color);'}
  }
  &:disabled {
    background-color: grey;
  }
  &:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  ${(props) => props.hasErrors && 'background-color: var(--danger-color); border-color: var(--danger-color);'}
`;

const CheckboxForm = styled.div`
  padding-left: 1.5em;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: normal;
  ${(props) => props.hasErrors && 'color: var(--danger-color);'}
`;

export default function Checkbox({ children, errors = [], ...props }) {
  const error = errors.find((e) => e.field === (props.field || props.name));

  return (
    <CheckboxForm>
      <CheckboxStyle {...props} type="checkbox" hasErrors={error !== undefined} />
      <Label htmlFor={props.id} hasErrors={error !== undefined}>
        {children}
      </Label>
      {error !== undefined && <ValidationMessage errors={error.messages} />}
    </CheckboxForm>
  );
}

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  field: ({ errors = [], ...props }, propName, componentName) => {
    if (
      errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`
      );
    }
  },
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
};

import { loadQuery } from 'react-relay/hooks';
import JSResource from './routing/js-resource';
import RelayEnvironment from './RelayEnvironment';

const routes = [
  {
    path: '/404',
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    prepare: () => ({}),
    routes: [
      {
        path: '/404',
        component: JSResource('NotFound', () => import('./Components/NotFound/NotFound')),
        prepare: () => ({}),
      },
    ],
  },
  {
    path: '/tournament-view/:id',
    exact: true,
    component: JSResource('AutoScrollContainer', () => import('./AutoScroll/AutoScrollContainer')),
    prepare: (params) => {
      const AutoScrollContainerQuery = require('./AutoScroll/__generated__/AutoScrollContainerQuery.graphql');
      return {
        autoScrollContainerQuery: loadQuery(
          RelayEnvironment,
          AutoScrollContainerQuery,
          { id: params.id },
          { fetchPolicy: 'network-only' }
        ),
      };
    },
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/sessions',
    prepare: () => ({}),
    routes: [
      {
        path: '/sessions/new',
        exact: true,
        component: JSResource('Session', () => import('./Components/Session/Container')),
        prepare: () => ({}),
      },
      {
        path: '/sessions/unlock/:token',
        exact: true,
        component: JSResource('UnlockUser', () => import('./Components/UnlockUser/UnlockUser')),
        prepare: () => ({}),
      },
    ],
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/confirm-email-address',
    prepare: () => ({}),
    routes: [
      {
        path: '/confirm-email-address/:token',
        exact: true,
        component: JSResource('ConfirmEmail', () => import('./Components/ConfirmEmail/ConfirmEmail')),
        prepare: (params) => {
          const ConfirmEmailQuery = require('./Components/ConfirmEmail/__generated__/ConfirmEmailQuery.graphql');
          return {
            confirmEmailQuery: loadQuery(RelayEnvironment, ConfirmEmailQuery, { token: params.token }),
          };
        },
      },
    ],
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/confirm-team',
    prepare: () => ({}),
    routes: [
      {
        path: '/confirm-team/:token',
        exact: true,
        component: JSResource('ConfirmTeam', () => import('./Components/ConfirmTeam/ConfirmTeam')),
        prepare: (params) => {
          const ConfirmTeamQuery = require('./Components/ConfirmTeam/__generated__/ConfirmTeamQuery.graphql');
          return {
            confirmTeamQuery: loadQuery(RelayEnvironment, ConfirmTeamQuery, { token: params.token }),
          };
        },
      },
    ],
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/invitations',
    prepare: () => ({}),
    routes: [
      {
        path: '/invitations/:token',
        exact: true,
        component: JSResource('invitation', () => import('./Components/Invitation/Invitation')),
        prepare: ({ token }) => {
          const InvitationQuery = require('./Components/Invitation/__generated__/InvitationQuery.graphql');
          return {
            invitationQuery: loadQuery(RelayEnvironment, InvitationQuery, { token }),
          };
        },
      },
    ],
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/account',
    prepare: () => ({}),
    routes: [
      {
        path: '/account/create',
        exact: true,
        component: JSResource('CreateAccount', () => import('./Components/CreateAccount/CreateAccount')),
        prepare: () => ({}),
      },
    ],
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/password-reset',
    prepare: () => ({}),
    routes: [
      {
        path: '/password-reset/new',
        exact: true,
        component: JSResource('PasswordNew', () => import('./Components/PasswordReset/PasswordResetNew')),
        prepare: () => ({}),
      },
      {
        path: '/password-reset/:token',
        exact: true,
        component: JSResource('PasswordEdit', () => import('./Components/PasswordReset/PasswordResetEdit')),
        prepare: (params) => {
          const PasswordResetEditQuery = require('./Components/PasswordReset/__generated__/PasswordResetEditQuery.graphql');
          return {
            passwordResetEditQuery: loadQuery(RelayEnvironment, PasswordResetEditQuery, { token: params.token }),
          };
        },
      },
    ],
  },
  {
    path: '/',
    component: JSResource('App', () => import('./App')),
    prepare: () => {
      const AppQuery = require('./__generated__/AppQuery.graphql');
      return {
        appQuery: loadQuery(RelayEnvironment, AppQuery, {}, { fetchPolicy: 'store-or-network' }),
      };
    },
    routes: [
      {
        path: '/',
        exact: true,
        component: JSResource('Home', () => import('./Components/Home/Home')),
        prepare: () => ({}),
      },
      {
        path: '/flights',
        exact: true,
        component: JSResource('Flight', () => import('./Components/Flight/Flight')),
        prepare: () => ({}),
      },
      {
        path: '/flights/:code',
        exact: true,
        component: JSResource('MyFlight', () => import('./Components/Flight/MyFlight')),
        prepare: (params) => {
          const MyFlightQuery = require('./Components/Flight/__generated__/MyFlightQuery.graphql');
          return {
            myFlightQuery: loadQuery(
              RelayEnvironment,
              MyFlightQuery,
              { code: params.code },
              { fetchPolicy: 'network-only' }
            ),
          };
        },
      },
      {
        path: '/players/:id',
        exact: true,
        component: JSResource('Player', () => import('./Components/Player/Player')),
        prepare: (params) => {
          const PlayerQuery = require('./Components/Player/__generated__/PlayerQuery.graphql');
          return {
            playerQuery: loadQuery(
              RelayEnvironment,
              PlayerQuery,
              { id: params.id },
              { fetchPolicy: 'store-or-network' }
            ),
          };
        },
      },
      {
        path: '/players',
        exact: true,
        component: JSResource('Players', () => import('./Components/Players/Players')),
        prepare: () => {
          const PlayersQuery = require('./Components/Players/__generated__/PlayersQuery.graphql');
          const storageKey = 'players-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            limit: 10,
            page: 1,
            query: '',
            sortBy: 'name',
            sortDirection: 'asc',
          };
          return {
            playersQuery: loadQuery(RelayEnvironment, PlayersQuery, filters, { fetchPolicy: 'network-only' }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/rankings',
        exact: true,
        component: JSResource('OrganisationRankings', () =>
          import('./Components/OrganisationRankings/OrganisationRankings')
        ),
        prepare: () => {
          const OrganisationRankingsQuery = require('./Components/OrganisationRankings/__generated__/OrganisationRankingsQuery.graphql');
          const storageKey = 'rankings-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            limit: 10,
            page: 1,
            query: '',
            sortBy: 'start_date',
            sortDirection: 'desc',
            status: 'active',
          };
          return {
            organisationRankingsQuery: loadQuery(RelayEnvironment, OrganisationRankingsQuery, filters, {
              fetchPolicy: 'network-only',
            }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/rankings/:id',
        exact: false,
        component: JSResource('RankingContainer', () => import('./Components/OrganisationRanking/RankingContainer')),
        prepare: (params) => {
          const RankingContainerQuery = require('./Components/OrganisationRanking/__generated__/RankingContainerQuery.graphql');
          return {
            rankingContainerQuery: loadQuery(
              RelayEnvironment,
              RankingContainerQuery,
              { id: params.id },
              { fetchPolicy: 'store-or-network' }
            ),
          };
        },
        routes: [
          {
            path: '/rankings/:id',
            exact: true,
            component: JSResource('Ranking', () => import('./Components/Ranking/Ranking')),
            prepare: (params) => {
              const RankingQuery = require('./Components/Ranking/__generated__/RankingQuery.graphql');
              return {
                rankingQuery: loadQuery(
                  RelayEnvironment,
                  RankingQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/rankings/:id/info',
            exact: true,
            component: JSResource('RankingInfo', () => import('./Components/OrganisationRanking/RankingInfo')),
            prepare: (params) => {
              const RankingInfoQuery = require('./Components/OrganisationRanking/__generated__/RankingInfoQuery.graphql');
              return {
                rankingInfoQuery: loadQuery(
                  RelayEnvironment,
                  RankingInfoQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/rankings/:id/:category',
            exact: true,
            component: JSResource('Ranking', () => import('./Components/Ranking/Ranking')),
            prepare: (params) => {
              const RankingQuery = require('./Components/Ranking/__generated__/RankingQuery.graphql');
              return {
                rankingQuery: loadQuery(
                  RelayEnvironment,
                  RankingQuery,
                  { category: params.category, id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
        ],
      },
      {
        path: '/edit-rankings/:id',
        exact: true,
        component: JSResource('OrganisationRanking', () =>
          import('./Components/OrganisationRanking/OrganisationRanking')
        ),
        prepare: (params) => {
          const OrganisationRankingQuery = require('./Components/OrganisationRanking/__generated__/OrganisationRankingQuery.graphql');
          const storageKey = 'ranking-tournaments-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            sortBy: 'start_date',
            sortDirection: 'desc',
          };
          return {
            organisationRankingQuery: loadQuery(
              RelayEnvironment,
              OrganisationRankingQuery,
              { id: params.id },
              { fetchPolicy: 'store-or-network' }
            ),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/organisations',
        exact: true,
        component: JSResource('Organisations', () => import('./Components/Organisations/Organisations')),
        prepare: () => {
          const OrganisationsQuery = require('./Components/Organisations/__generated__/OrganisationsQuery.graphql');
          const storageKey = 'organisations-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            limit: 25,
            page: 1,
            query: '',
            sortBy: 'name',
            sortDirection: 'asc',
          };
          return {
            organisationsQuery: loadQuery(RelayEnvironment, OrganisationsQuery, filters, {
              fetchPolicy: 'network-only',
            }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/organisations/:id',
        exact: false,
        component: JSResource('Organisation', () => import('./Components/Organisation/Organisation')),
        prepare: (params) => {
          const OrganisationQuery = require('./Components/Organisation/__generated__/OrganisationQuery.graphql');
          return {
            organisationQuery: loadQuery(
              RelayEnvironment,
              OrganisationQuery,
              { id: params.id },
              { fetchPolicy: 'store-or-network' }
            ),
          };
        },
        routes: [
          {
            path: '/organisations/:id/courses',
            exact: true,
            component: JSResource('OrganisationCourses', () =>
              import('./Components/OrganisationCourses/OrganisationCourses')
            ),
            prepare: (params) => {
              const OrganisationCoursesQuery = require('./Components/OrganisationCourses/__generated__/OrganisationCoursesQuery.graphql');
              return {
                organisationCoursesQuery: loadQuery(
                  RelayEnvironment,
                  OrganisationCoursesQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/organisations/:organisationId/courses/:courseId/course-layouts',
            exact: true,
            component: JSResource('CourseLayouts', () => import('./Components/CourseLayouts/CourseLayouts')),
            prepare: (params) => {
              const CourseLayoutsQuery = require('./Components/CourseLayouts/__generated__/CourseLayoutsQuery.graphql');
              const storageKey = 'course-layouts-filter';
              const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
                limit: 10,
                page: 1,
                query: '',
                sortBy: 'id',
                sortDirection: 'desc',
                status: 'active',
              };
              return {
                courseLayoutsQuery: loadQuery(
                  RelayEnvironment,
                  CourseLayoutsQuery,
                  {
                    id: params.courseId,
                    organisationId: params.organisationId,
                    ...filters,
                  },
                  {
                    fetchPolicy: 'network-only',
                  }
                ),
                filters,
                storageKey,
              };
            },
          },
          {
            path: '/organisations/:organisationId/courses/:courseId/course-layouts/:id',
            exact: true,
            component: JSResource('CourseLayout', () => import('./Components/CourseLayout/CourseLayout')),
            prepare: (params) => {
              const CourseLayoutQuery = require('./Components/CourseLayout/__generated__/CourseLayoutQuery.graphql');
              return {
                courseLayoutQuery: loadQuery(
                  RelayEnvironment,
                  CourseLayoutQuery,
                  { id: params.id, organisationId: params.organisationId },
                  { fetchPolicy: 'store-or-network' }
                ),
              };
            },
          },
          {
            path: '/organisations/:id/details',
            exact: true,
            component: JSResource('OrganisationDetails', () =>
              import('./Components/OrganisationDetails/OrganisationDetails')
            ),
            prepare: (params) => {
              const OrganisationDetailsQuery = require('./Components/OrganisationDetails/__generated__/OrganisationDetailsQuery.graphql');
              return {
                organisationDetailsQuery: loadQuery(
                  RelayEnvironment,
                  OrganisationDetailsQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/organisations/:id/members',
            exact: true,
            component: JSResource('OrganisationMembers', () =>
              import('./Components/OrganisationMembers/OrganisationMembers')
            ),
            prepare: (params) => {
              const OrganisationMembersQuery = require('./Components/OrganisationMembers/__generated__/OrganisationMembersQuery.graphql');
              return {
                organisationMembersQuery: loadQuery(
                  RelayEnvironment,
                  OrganisationMembersQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
        ],
      },
      {
        path: '/player-profiles/:id',
        exact: false,
        component: JSResource('PlayerProfile', () => import('./Components/PlayerProfile/PlayerProfile')),
        prepare: (params) => {
          const PlayerProfileQuery = require('./Components/PlayerProfile/__generated__/PlayerProfileQuery.graphql');
          return {
            playerProfileQuery: loadQuery(
              RelayEnvironment,
              PlayerProfileQuery,
              { id: params.id },
              { fetchPolicy: 'store-or-network' }
            ),
          };
        },
        routes: [
          {
            path: '/player-profiles/:id',
            exact: true,
            component: JSResource('PlayerProfileStats', () => import('./Components/PlayerProfile/PlayerProfileStats')),
            prepare: (params) => {
              const PlayerProfileStatsQuery = require('./Components/PlayerProfile/__generated__/PlayerProfileStatsQuery.graphql');
              return {
                playerProfileStatsQuery: loadQuery(
                  RelayEnvironment,
                  PlayerProfileStatsQuery,
                  { id: params.id },
                  { fetchPolicy: 'store-or-network' }
                ),
              };
            },
          },
          {
            path: '/player-profiles/:id/results',
            exact: true,
            component: JSResource('PlayerProfileResults', () =>
              import('./Components/PlayerProfile/PlayerProfileResults')
            ),
            prepare: (params) => {
              const PlayerProfileResultsQuery = require('./Components/PlayerProfile/__generated__/PlayerProfileResultsQuery.graphql');
              return {
                playerProfileResultsQuery: loadQuery(
                  RelayEnvironment,
                  PlayerProfileResultsQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
        ],
      },
      {
        path: '/profile',
        exact: true,
        component: JSResource('Profile', () => import('./Components/Profile/Profile')),
        prepare: (params) => {
          const ProfileQuery = require('./Components/Profile/__generated__/ProfileQuery.graphql');
          return {
            profileQuery: loadQuery(RelayEnvironment, ProfileQuery, {}, { fetchPolicy: 'store-or-network' }),
          };
        },
      },
      {
        path: '/tournaments',
        exact: true,
        component: JSResource('Tournaments', () => import('./Components/Tournaments/Tournaments')),
        prepare: () => {
          const TournamentsQuery = require('./Components/Tournaments/__generated__/TournamentsQuery.graphql');
          const storageKey = 'tournaments-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            sortBy: 'start_date',
            sortDirection: 'asc',
            statuses: ['active', 'open_for_registration', 'finished', 'published'],
          };
          return {
            tournamentsQuery: loadQuery(RelayEnvironment, TournamentsQuery, filters, { fetchPolicy: 'network-only' }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/tournaments/:id',
        exact: false,
        component: JSResource('Tournament', () => import('./Components/Tournament/Tournament')),
        prepare: (params) => {
          const TournamentQuery = require('./Components/Tournament/__generated__/TournamentQuery.graphql');
          return {
            tournamentQuery: loadQuery(
              RelayEnvironment,
              TournamentQuery,
              { id: params.id },
              { fetchPolicy: 'store-or-network' }
            ),
          };
        },
        routes: [
          {
            path: '/tournaments/:id/competition-leaderboard',
            exact: true,
            component: JSResource('CompetitionLeaderboard', () =>
              import('./Components/CompetitionLeaderboard/CompetitionLeaderboard')
            ),
            prepare: (params) => {
              const CompetitionLeaderboardQuery = require('./Components/CompetitionLeaderboard/__generated__/CompetitionLeaderboardQuery.graphql');
              const category = new URLSearchParams(window.location.search).get('category');
              return {
                competitionLeaderboardQuery: loadQuery(
                  RelayEnvironment,
                  CompetitionLeaderboardQuery,
                  { category, id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/hole-by-hole',
            exact: true,
            component: JSResource('HoleByHole', () => import('./Components/HoleByHole/HoleByHole')),
            prepare: (params) => {
              const HoleByHoleQuery = require('./Components/HoleByHole/__generated__/HoleByHoleQuery.graphql');
              const category = new URLSearchParams(window.location.search).get('category');
              const round = new URLSearchParams(window.location.search).get('round');
              const aggregated =
                new URLSearchParams(window.location.search).get('aggregated') === 'true' && ['2', '3'].includes(round);

              return {
                holeByHoleQuery: loadQuery(
                  RelayEnvironment,
                  HoleByHoleQuery,
                  { aggregated, category, id: params.id, round: parseInt(round) },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/info',
            exact: true,
            component: JSResource('TournamentInfo', () => import('./Components/Tournament/TournamentInfo')),
            prepare: (params) => {
              const TournamentInfoQuery = require('./Components/Tournament/__generated__/TournamentInfoQuery.graphql');
              return {
                tournamentInfoQuery: loadQuery(
                  RelayEnvironment,
                  TournamentInfoQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/leaderboard',
            exact: true,
            component: JSResource('Leaderboard', () => import('./Components/Leaderboard/Leaderboard')),
            prepare: (params) => {
              const LeaderboardQuery = require('./Components/Leaderboard/__generated__/LeaderboardQuery.graphql');
              const category = new URLSearchParams(window.location.search).get('category');
              const round = new URLSearchParams(window.location.search).get('round');
              const aggregated =
                new URLSearchParams(window.location.search).get('aggregated') === 'true' && ['2', '3'].includes(round);

              return {
                leaderboardQuery: loadQuery(
                  RelayEnvironment,
                  LeaderboardQuery,
                  { aggregated, category, id: params.id, round: parseInt(round) },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/leaderboard/:category',
            exact: true,
            component: JSResource('Leaderboard', () => import('./Components/Leaderboard/Leaderboard')),
            prepare: (params) => {
              const LeaderboardQuery = require('./Components/Leaderboard/__generated__/LeaderboardQuery.graphql');
              return {
                leaderboardQuery: loadQuery(
                  RelayEnvironment,
                  LeaderboardQuery,
                  { category: params.category, id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/matchplay-leaderboard',
            exact: true,
            component: JSResource('MatchplayLeaderboard', () =>
              import('./Components/MatchplayLeaderboard/MatchplayLeaderboard')
            ),
            prepare: (params) => {
              const MatchplayLeaderboardQuery = require('./Components/MatchplayLeaderboard/__generated__/MatchplayLeaderboardQuery.graphql');
              return {
                matchplayLeaderboardQuery: loadQuery(
                  RelayEnvironment,
                  MatchplayLeaderboardQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/pairs-plus-leaderboard',
            exact: true,
            component: JSResource('PairsPlusLeaderboard', () =>
              import('./Components/PairsPlusLeaderboard/PairsPlusLeaderboard')
            ),
            prepare: (params) => {
              const PairsPlusLeaderboardQuery = require('./Components/PairsPlusLeaderboard/__generated__/PairsPlusLeaderboardQuery.graphql');
              return {
                pairsPlusLeaderboardQuery: loadQuery(
                  RelayEnvironment,
                  PairsPlusLeaderboardQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/participants',
            exact: true,
            component: JSResource('Participants', () => import('./Components/Tournament/Participants')),
            prepare: (params) => {
              const ParticipantsQuery = require('./Components/Tournament/__generated__/ParticipantsQuery.graphql');
              return {
                participantsQuery: loadQuery(
                  RelayEnvironment,
                  ParticipantsQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/player-statistics',
            exact: true,
            component: JSResource('PlayerStats', () => import('./Components/TournamentStats/PlayerStats')),
            prepare: (params) => {
              const PlayerStatsQuery = require('./Components/TournamentStats/__generated__/PlayerStatsQuery.graphql');
              return {
                playerStatsQuery: loadQuery(
                  RelayEnvironment,
                  PlayerStatsQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/registration',
            exact: true,
            component: JSResource('Reservation', () => import('./Components/Reservation/Reservation')),
            prepare: (params) => {
              const ReservationQuery = require('./Components/Reservation/__generated__/ReservationQuery.graphql');
              return {
                reservationQuery: loadQuery(
                  RelayEnvironment,
                  ReservationQuery,
                  { id: params.id },
                  { fetchPolicy: 'store-or-network' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/team-strokeplay-leaderboard',
            exact: true,
            component: JSResource('TeamStrokeplayLeaderboard', () =>
              import('./Components/TeamStrokeplayLeaderboard/TeamStrokeplayLeaderboard')
            ),
            prepare: (params) => {
              const TeamStrokeplayLeaderboardQuery = require('./Components/TeamStrokeplayLeaderboard/__generated__/TeamStrokeplayLeaderboardQuery.graphql');
              return {
                teamStrokeplayLeaderboardQuery: loadQuery(
                  RelayEnvironment,
                  TeamStrokeplayLeaderboardQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/tournaments/:id/tee-times',
            exact: true,
            component: JSResource('TeeTimes', () => import('./Components/Tournament/TeeTimes')),
            prepare: (params) => {
              const TeeTimesQuery = require('./Components/Tournament/__generated__/TeeTimesQuery.graphql');
              return {
                teeTimesQuery: loadQuery(
                  RelayEnvironment,
                  TeeTimesQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
        ],
      },
      {
        path: '/edit-tournaments/:id',
        exact: false,
        component: JSResource('UpdateTournament', () => import('./Components/UpdateTournament/UpdateTournament')),
        prepare: (params) => {
          const UpdateTournamentQuery = require('./Components/UpdateTournament/__generated__/UpdateTournamentQuery.graphql');
          return {
            updateTournamentQuery: loadQuery(
              RelayEnvironment,
              UpdateTournamentQuery,
              { id: params.id },
              { fetchPolicy: 'store-or-network' }
            ),
          };
        },
        routes: [
          {
            path: '/edit-tournaments/:id/setup',
            exact: true,
            component: JSResource('TournamentSetup', () => import('./Components/TournamentSetup/TournamentSetup')),
            prepare: (params) => {
              const TournamentSetupQuery = require('./Components/TournamentSetup/__generated__/TournamentSetupQuery.graphql');
              return {
                tournamentSetupQuery: loadQuery(
                  RelayEnvironment,
                  TournamentSetupQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/edit-tournaments/:id/competition',
            exact: true,
            component: JSResource('Competition', () =>
              import('./Components/CompetitionConfiguration/CompetitionConfiguration')
            ),
            prepare: (params) => {
              const CompetitionConfigurationQuery = require('./Components/CompetitionConfiguration/__generated__/CompetitionConfigurationQuery.graphql');
              return {
                competitionConfigurationQuery: loadQuery(
                  RelayEnvironment,
                  CompetitionConfigurationQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/edit-tournaments/:id/registration-settings',
            exact: true,
            component: JSResource('TournamentRegistration', () =>
              import('./Components/TournamentRegistration/TournamentRegistration')
            ),
            prepare: (params) => {
              const TournamentRegistrationQuery = require('./Components/TournamentRegistration/__generated__/TournamentRegistrationQuery.graphql');
              return {
                tournamentRegistrationQuery: loadQuery(
                  RelayEnvironment,
                  TournamentRegistrationQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/edit-tournaments/:id/players',
            exact: true,
            component: JSResource('TournamentPlayers', () =>
              import('./Components/TournamentPlayers/TournamentPlayers')
            ),
            prepare: (params) => {
              const TournamentPlayersQuery = require('./Components/TournamentPlayers/__generated__/TournamentPlayersQuery.graphql');
              const storageKey = 'tournament-users-filter';
              const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
                limit: 10,
                page: 1,
                query: '',
                sortBy: 'name',
                sortDirection: 'asc',
              };
              return {
                tournamentPlayersQuery: loadQuery(
                  RelayEnvironment,
                  TournamentPlayersQuery,
                  { id: params.id, tournamentId: parseInt(params.id), ...filters },
                  { fetchPolicy: 'store-or-network' }
                ),
                filters,
                storageKey,
              };
            },
          },
          {
            path: '/edit-tournaments/:id/strokeplay-teams',
            exact: true,
            component: JSResource('StrokeplayTeams', () => import('./Components/StrokeplayTeams/StrokeplayTeams')),
            prepare: (params) => {
              const StrokeplayTeamsQuery = require('./Components/StrokeplayTeams/__generated__/StrokeplayTeamsQuery.graphql');
              return {
                strokeplayTeamsQuery: loadQuery(
                  RelayEnvironment,
                  StrokeplayTeamsQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/edit-tournaments/:id/matchplays',
            exact: true,
            component: JSResource('MatchplayRounds', () => import('./Components/UpdateTournament/MatchplayRounds')),
            prepare: (params) => {
              const MatchplayRoundsQuery = require('./Components/UpdateTournament/__generated__/MatchplayRoundsQuery.graphql');
              return {
                matchplayRoundsQuery: loadQuery(
                  RelayEnvironment,
                  MatchplayRoundsQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
          {
            path: '/edit-tournaments/:id/pairs',
            exact: true,
            component: JSResource('Pairs', () => import('./Components/Pairs/Pairs')),
            prepare: (params) => {
              const PairsQuery = require('./Components/Pairs/__generated__/PairsQuery.graphql');
              return {
                pairsQuery: loadQuery(RelayEnvironment, PairsQuery, { id: params.id }, { fetchPolicy: 'network-only' }),
              };
            },
          },
          {
            path: '/edit-tournaments/:id/flights',
            exact: true,
            component: JSResource('TournamentRounds', () => import('./Components/UpdateTournament/TournamentRounds')),
            prepare: (params) => {
              const TournamentRoundsQuery = require('./Components/UpdateTournament/__generated__/TournamentRoundsQuery.graphql');
              return {
                tournamentRoundsQuery: loadQuery(
                  RelayEnvironment,
                  TournamentRoundsQuery,
                  { id: params.id },
                  { fetchPolicy: 'network-only' }
                ),
              };
            },
          },
        ],
      },
    ],
  },
];

export default routes;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Btn = styled.button`
  background-color: transparent;
  border: none;
  ${({ straight }) => (straight ? 'border-radius: 0;' : 'border-radius: 8px;')};
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  transition-property: background-color, border-color, color;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  ${({ size }) => {
    switch (size) {
      case 'xl':
        return 'height: 100%; padding: 0 1.5rem;';
      case 'lg':
        return 'padding: 0.75rem 1rem;';
      case 'sm':
        return 'padding: 0 1rem;';
      case 'sm-min':
        return 'padding: 0.25rem 0.5rem;';
      case 'xs':
        return 'padding: 0;';
      case 'xxs':
        return 'padding: auto;';
      default:
        return 'padding: 0.5rem 1rem;';
    }
  }}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

export const StyledButton = styled(Btn)`
  background-color: ${({ variant }) => `var(--${variant}-color)`};
  border: 2px solid transparent;
  color: ${({ variant }) => `var(--${variant}-color-text)`};

  &:hover {
    ${({ variant }) =>
      `background-color: hsl(
        var(--${variant}-color-h),
        var(--${variant}-color-s),
        calc(var(--${variant}-color-l) - 10%)
      );
      border-color: hsl(
        var(--${variant}-color-h),
        var(--${variant}-color-s),
        calc(var(--${variant}-color-l) - 10%)
      );`}
  }

  ${({ navigation }) => navigation && 'background-color: var(--black);'}
`;

const StyledOutlineButton = styled(Btn)`
  background-color: transparent;
  border: 2px solid ${({ variant }) => `var(--${variant}-color)`};
  color: ${({ variant }) => `var(--${variant}-color)`};

  &:hover {
    ${({ variant }) =>
      `background-color: hsl(
        var(--${variant}-color-h),
        var(--${variant}-color-s),
        95%
      );`}
  }
`;

export default function Button({ fullWidth = false, outline = false, variant = 'primary', ...props }) {
  return outline ? (
    <StyledOutlineButton fullWidth={fullWidth} variant={variant} {...props} />
  ) : (
    <StyledButton fullWidth={fullWidth} variant={variant} {...props} />
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  size: PropTypes.oneOf(['', 'xl', 'lg', 'sm', 'sm-min', 'xs', 'xxs']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'success', 'danger']),
};
